import Theme from '@/shared/app/model/Theme';
import defaultTheme from '@/shared/app/theme/default.theme';

export const compassTheme: Theme = {
  ...defaultTheme,
  customerName: 'Compass',
  primaryColor: '#056173',
  secondaryColor: '#37474f',
  accentColor: '#ffc107',
  errorColor: '#f44336',
  warningColor: '#ff9800',
  infoColor: '#00bcd4',
  successColor: '#4caf50',
};
